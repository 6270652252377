.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-h-mob);
  padding-bottom: 50px;
 
  &__logo {
    max-width: 214px;
  }

  &__nav {
    display: none;
  }

  .btn {
    max-width: 188px;
    font-size: 15px;
  }
}

@media screen and (max-width: 479px) {
  .header {
    padding: 10px;
    padding-bottom: 20px;

    &__logo {
      max-width: 130px;
    }

    .btn {
      max-width: 158px;
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 1100px) {
  .header {
    &__nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      width: 100%;
    }

    &__nav-link {
      font-size: 17px;
      
      &:hover, 
      &:active {
        color: var(--accent-color);
      }
    }
  }
}