.contacts {
  position: relative;

  &__title {
    max-width: 424px;
    margin-bottom: 20px;
    font-size: 36px;
    text-align: right;

    &--accent {
      display: block;
      text-align: start;
      color: var(--accent-color);
      font-size: 49px;
    }
  }

  &__text {
    max-width: 596px;
    margin-bottom: 42px;
    font-family: "Stem-Regular", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 20px;
  }

  &__social {
    margin-bottom: 32px;
  }

  &__social,
  &__social-icons {
    display: flex;
    align-items: center;
  }

  &__social-text {
    margin-right: 16px;
    font-size: 36px;
  }

  &__social-icons {
    margin-left: auto;
  }

  &__social-link {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--accent-color);

    &:first-child {
      margin-right: 26px;
    }
  }

  &__links {
    max-width: 725px;
    margin-bottom: 76px;
  }

  &__link {
    display: block;
    font-size: 40px;
    color: var(--dark-text-color);

    &:hover,
    &:active {
      color: var(--accent-color);
    }
  }

  &__buttons {
    margin-bottom: 20px;

    .btn {
      margin-bottom: 30px;
      color: var(--dark-text-color);

      &:hover {
        color: var(--white-color);
      }
    }

    .btn--accent {
      color: var(--white-color);
    }
  }

  &__icon {
    width: 32px;
    height: 20px;
  }
}

@media screen and (max-width: 479px) {
  .contacts {

    &__title {
      font-size: 30px;
    }

    &__title--accent {
      font-size: 34px;
    }

    &__text {
      font-size: 16px;
    }

    &__social-text {
      font-size: 16px;
    }

    &__social-link {
      width: 40px;
      height: 40px;
  
      &:first-child {
        margin-right: 10px;
      }
    }

    &__link {
      font-size: 28px;
    }

    &__icon {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (min-width: 768px) {
  .contacts {

    &__title {
      max-width: 560px;
      margin: 0 auto;
      margin-bottom: 80px;
      font-size: 50px;
    }

    &__title--accent {
      font-size: 64px;
    }

    &__social-icons {
      margin-left: 0;
    }
  }
}

@media screen and (min-width: 1240px) {
  .contacts {

    &__title {
      max-width: 860px;
      font-size: 66px;
      margin-bottom: 40px;
    }

    &__title--accent {
      font-size: 100px;
    }

    &__social-text,
    &__link {
      font-size: 66px;
    }

    &__icon {
      width: 36px;
      height: 25px;
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: 26px;

      .btn {
        margin-bottom: 0;
      }
    }
  }
}