.location {
  position: relative;
  background-color: var(--white-color);
  padding-bottom: 4px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 55px;
    background-image: url('../images/gde.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  
  &__title {
    max-width: 230px;
    margin-bottom: 24px;
    font-size: 30px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 20px;
      right: 10px;
      width: 40px;
      height: 50px;
      background-image: url('../images/map_point.svg');
      background-repeat: no-repeat;
    }
  }

  .swiper-address {
    position: static;
    margin-bottom: 30px;

    .swiper-slide {
      width: auto !important;
    }

    &__btn {
      cursor: pointer;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid var(--bg-light-color);
      background: none;
      font-family: "Stem-Regular", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 16px;
      color: #3a3a3a;

      &.active {
        border: none;
        background-color: var(--accent-color);
        color: var(--white-color);
      }
    }

    .swiper-button-prev.swiper-button-disabled, 
    .swiper-button-next.swiper-button-disabled {
      display: none;
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: 149px;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background-color: var(--white-color);
      box-shadow: 0 0 13px 8px rgba($color: #4e4e4d, $alpha: .11);

      svg {
        width: 8px;
        fill: var(--bg-dark-color);
      }
    }
  }

  &__wrapper {
    max-width: 720px;
    margin: 0 auto;
  }

  &__map-list {
    height: 275px;
  }

  &__map {
    display: none;

    &.active {
      display: block;
    }
    
    .ymap {
      width: 100%;
    }
  }

  &__slider {
    display: none;
    position: relative;
    margin-bottom: 40px;
    width: 100%;
    height: 500px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    overflow: hidden;

    &.active {
      display: block;
    }

    img {
      height: 100%;
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background-color: var(--accent-color);

      svg {
        width: 8px;
        fill: var(--white-color);
      }
      
    }

    .swiper-button-prev.swiper-button-disabled, 
    .swiper-button-next.swiper-button-disabled {
      background-color: var(--white-color);
      opacity: .8;

      svg {
        fill: var(--bg-dark-color);
      }
    }
  }
}

@media screen and (min-width: 479px) {
  .location {
    &__title {
      max-width: 276px;
      font-size: 36px;

      &::after {
        top: 24px;
        right: 20px;
      }
    }

    .swiper-address {

      .swiper-button-prev,
      .swiper-button-next {
        top: 162px;
        right: 20px;
        width: 48px;
        height: 48px;
      }
    }

    &__slider {
      .swiper-button-prev,
      .swiper-button-next {
        width: 48px;
        height: 48px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .location {
    border-radius: 30px;

    &::before {
      right: 20px;
      left: unset;
      height: 80px;
      background-position: right;
    }
  }
}

@media screen and (min-width: 1240px) {
  .location {
    padding-bottom: var(--padding-v-desktop);
    
    &::before {
      top: -125px;
      right: 60px;
      height: 121px;
    }

    &__title {
      max-width: unset;

      &::after {
        right: 26px;
      }
    }

    .swiper-address {
      width: 1052px;
      margin-bottom: 45px;
      overflow: hidden;

      .swiper-button-prev,
      .swiper-button-next {
        top: 140px;
      }

      .swiper-button-next {
        right: 80px;
      }

      .swiper-button-prev {
        right: unset;
        left: 80px;
      }
    }

    &__wrapper {
      display: flex;
      max-width: unset;
      border-radius: 30px;
      overflow: hidden;
    }

    &__map-list {
      width: 326px;
      height: 500px;
    }

    .ymap {
      height: 500px;
    }

    &__clubs-slider {
      width: 765px;
      height: 500px;
      overflow: hidden;
    }

    &__slider {
      border-radius: 0;
    }
  }
}