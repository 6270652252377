.corporate {
  position: relative;
  padding-bottom: 16px;
  background-color: var(--bg-dark-color);
  color: var(--white-color);
  background-image: 
    linear-gradient(to top, rgba(0,0,0,0.38), rgba(0,0,0,0.38)), 
    url('../images/ellips.png'), 
    url('../images/ellips-bottom.png'),
    url('../images/woman-bg.webp');
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size:  cover, 400px 400px, 380px 300px, 1090px;
  background-position:  center, top right, -3px 100%, calc(100% + 263px) bottom;
  overflow-x: hidden;

  &__title {
    margin-bottom: 42px;
    font-size: 30px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  &__list {
    max-width: 406px;
  }

  &__item {
    position: relative;
    margin-bottom: 28px;
    padding-left: 36px;
    font-family: "Stem-Regular", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 15px;

    &::before {
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      position: absolute;
      top: 10px;
      left: 0;
      background-color: var(--accent-color);
    }
  }

  &__notice {
    margin-bottom: 72px;
    font-size: 45px;
  }

  &__notice-old {
    position: relative;
    display: block;
    font-size: 34px;
    color: #d8d8d8;

    &::after {
      content: '';
      display: block;
      width: 187px;
      height: 3px;
      position: absolute;
      bottom: 17px;
      left: 0;
      background-color: var(--accent-color);
      transform: rotate(-5deg);
    }
  }

  &__buttons {

    .btn {
      margin-bottom: 30px;
    }
  }

  &__transparent-text {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 58px;
  }
}

@media screen and (min-width: 479px) {
  .corporate {
    background-size: cover, 600px 600px, 380px 600px, 1172px;
    background-position: center, top right, -3px 100%, calc(100% + 220px) bottom;

    &__title {
      font-size: 36px;
    }

    &__item {
      font-size: 20px;
    }

    &__notice {
      font-size: 55px;
      line-height: 1;
    }
  
    &__notice-old {
      font-size: 38px;

      &::after {
        width: 210px;
      }
    }

    &__transparent-text {
      max-width: 76px;
    }
  }
}

@media screen and (min-width: 1240px) {
  .corporate {
    padding-bottom: 40px;
    border-radius: 30px;
    overflow: hidden;
    background-image: 
    linear-gradient(to top, rgba(0,0,0,0.38), rgba(0,0,0,0.38)), 
    url('../images/ellips.png'), 
    url('../images/ellips-bottom.png'),
    linear-gradient(to right, rgb(0,0,0), rgba(0,0,0,0.38)), 
    url('../images/woman-bg.webp');
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-size: cover, 600px 600px, 380px 600px, 180px, 1076px;
    background-position: center, top right, -3px 100%, 23%, calc(100% + 89px) center;

    &__title {
      max-width: 768px;
      font-size: 55px;
    }

    &__wrapper {
      flex-direction: row;
      align-items: center;
      gap: 240px;
    }

    &__notice {
      font-size: 93px;
      line-height: 1;
      margin-bottom: auto;
    }

    &__notice-old {
      display: flex;
      width: fit-content;

      &::after {
        bottom: 16px;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: 26px;
      margin-top: 50px;
    } 
    
    &__transparent-text {
      bottom: 32px;
    }
  }

}