.advantages {
  position: relative;
  padding-bottom: 16px;
  background-color: var(--bg-dark-color);
  color: var(--white-color);
  background-image: 
    linear-gradient(to top, rgba(0,0,0,0.38), rgba(0,0,0,0.38)), 
    url('../images/ellips-bottom-big.png'), 
    linear-gradient(to bottom, rgb(0,0,0), rgba(0,0,0,0.38)), 
    url('../images/man-bg.webp');
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: cover, 800px 700px, 90px, 1020px;
  background-position: center, 50% 100%, top left, calc(100% + 390px) bottom;
  overflow-x: hidden;
  
  &__title {
    position: relative;
    font-size: 40px;
    max-width: 540px;
  }

  &__logo {
    position: absolute;
    top: 40%;
    left: 0;
    max-width: 550px;
  }

  &__list {
    max-width: 500px;
    margin-top: 120px;
  }

  &__item {
    position: relative;
    margin-bottom: 28px;
    padding-left: 36px;
    font-family: "Stem-Regular", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 16px;

    &::before {
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      position: absolute;
      top: 10px;
      left: 0;
      background-color: var(--accent-color);
    }
  }

  &__buttons {
    margin-top: 46px;

    .btn {
      margin-bottom: 30px;
    }
  }

  &__notice {
    font-size: 20px;

    b {
      display: block;
      font-size: 55px;
    }
  }
}

@media screen and (max-width: 479px) {
  .advantages {

    &__title {
      font-size: 28px;
    }

    &__logo {
      max-width: 350px;
    }

    &__list {
      margin-top: 86px;
    }

    &__item {
      font-size: 15px;
    }

    &__notice {
  
      b {
        display: block;
        font-size: 50px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .advantages {
    border-radius: 30px;
    background-image: 
      linear-gradient(to top, rgba(0,0,0,0.38), rgba(0,0,0,0.38)),
      url('../images/ellips.png'),  
      url('../images/ellips-bottom-big.png'), 
      linear-gradient(to right, rgb(0,0,0), rgba(0,0,0,0.38)), 
      url('../images/man-bg.webp');
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-size: cover, 480px 700px, 900px 400px, 189px, 1020px;
    background-position: top right, top right, 50% 100%, top left, calc(100% + 200px) bottom;

   
  }
}

@media screen and (min-width: 1240px) {
  .advantages {
    background-size: cover, 480px 700px, 900px 400px, 193px, 1048px;
    background-position: top right, top right, 50% 100%, 18%, right bottom;

    &__title {
      position: relative;
      font-size: 55px;

      &::after {
        content: '';
        display: block;
        width: 120px;
        height: 20px;
        position: absolute;
        bottom: -30px;
        right: -100px;
        background-image: url('../images/community.png');
      }
    }

    &__logo {
      width: 760px;
      max-width: unset;
      top: 22%;
    }

    &__list {
      margin-top: 190px;
    }

    &__item {
      font-size: 20px;
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: 26px;
      margin-top: 76px;
      margin-bottom: 40px;

      .btn {
        margin-bottom: 0;
      }
    }

    &__notice {
      position: absolute;
      bottom: 10%;
      right: 60px;
    }
  }
}