.equipment {
  padding-bottom: 182px;

  &__title {
    margin-bottom: 56px;
    font-size: 36px;
  }

  &__slider {
    position: relative;
    margin: 0 auto;
    margin-bottom: 40px;
    width: 100%;
    max-width: 430px;
    max-height: 287px;
    box-shadow: 12px -12px 0 var(--accent-color);
    border-radius: 30px;
    overflow: hidden;

    .swiper-button-prev,
    .swiper-button-next {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: var(--accent-color);

      svg {
        width: 8px;
        fill: var(--white-color);
      }
      
    }

    .swiper-button-prev.swiper-button-disabled, 
    .swiper-button-next.swiper-button-disabled {
      background-color: var(--white-color);
      opacity: .8;

      svg {
        fill: var(--bg-dark-color);
      }
    }
  }

  &__sponsors-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 44px;
    margin-bottom: 30px;
  }

  &__sponsors-item {
    max-width: 150px;
  }

  &__sponsors-info {
    span {
      display: block;
      font-size: 13px;
      margin-bottom: 2px;
      text-transform: uppercase;
      color: #b5b5b5;
    }

    b {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 479px) {
  .equipment {
  
    &__title {
      font-size: 30px;
    }
  
    &__slider {
      position: relative;
      margin-bottom: 40px;
      width: 100%;
      max-width: 280px;
      box-shadow: 12px -12px 0 var(--accent-color);
      border-radius: 30px;
      overflow: hidden;
  
      .swiper-button-prev,
      .swiper-button-next {
        width: 44px;
        height: 44px;
      }
    }
  
    &__sponsors-item {
      max-width: 120px;
    }
  }
}

@media screen and (min-width: 1240px) {
  .equipment {
    &__title {
      font-size: 55px;
    }

    &__wrapper {
      display: flex;
      flex-direction: row-reverse;
      gap: 45px;
    }

    &__sponsors {
      width: 51%;
      margin-top: 30px;
    }

    &__sponsors-list {
      margin-bottom: 80px;
    }

    &__slider {
      max-width: unset;
      max-height: 454px;
    }
  }
}