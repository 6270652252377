.prices {
  padding-bottom: 80px;
  
  &__title {
    margin-bottom: 44px;
    max-width: 220px;
    font-size: 30px;
  }

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    max-width: 336px;
  }

  &__image {
    border-radius: 30px;
  }
}

@media screen and (min-width: 479px) {
  .prices {
    &__title {
      font-size: 36px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .prices {
    &__title {
      max-width: unset;
      font-size: 55px;
    }

    &__list {
      gap: 32px;
    }
  }
}