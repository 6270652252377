:root {
  --bg-light-color: #fafafa;
  --bg-dark-color: #000000;
  --white-color: #ffffff;
  --accent-color: #d5001c;
  --dark-text-color: #151515;

  --padding-h-mob: 20px;
  --padding-v-mob: 40px;
  --padding-h-desktop: 84px;
  --padding-v-desktop: 60px;
}