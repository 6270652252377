.modal {
  display: none;

  &.show {
    display: block;
  }

  &__wrapper {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: .7);
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    padding: 20px;
    border-radius: 20px;
    background-color: var(--white-color);
  }

  &__title {
    text-align: center;
  }

  &__close {
    position: absolute;
    top: -20px;
    right: 10px;
    color: var(--white-color);
  }

  &__form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 8px;
    }

    .errorMessage {
      display: block;
      max-width: 300px;
      width: 100%;
      background-color: rgb(231, 76, 60);
      border: 1px dashed black;
      border-radius: 20px;
      color: rgb(255, 255, 255);
      font-size: 12px;
      margin: 0 auto 10px;
      padding: 6px;
      opacity: .9;
    }
    
    .d-none {
      display: none;
    }
    
    .btn-submit {
      height: 44px;
    }
    
    .btn-submit:disabled {
      background-color: grey;
      opacity: 0.6;
    }
  }

  &__input {
    margin-bottom: 10px;
    padding: 8px;
    font-family: "Stem-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.2;
    border-radius: 20px;
    border: 1px solid var(--dark-text-color);
    resize: none;
  }

  &__checkbox-text {
    font-size: 12px;
    font-weight: 300;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    display: flex;
    gap: 0.5rem;
    margin: 0.5rem 0;
    align-items: flex-start;
  }
}