.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  padding: 18px 20px;
  background-color: var(--bg-dark-color);
  color: var(--white-color);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-family: "Stem-Regular", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 20px;

  &__logo {
    max-width: 188px;
  }
}

@media screen and (min-width: 768px) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
    padding: 13px 20px;

    &__logo {
      max-width: 213px;
    }
  }
}