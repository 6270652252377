@font-face {
  font-family: "Stem-Bold";
  src: url("../fonts/Stem-Bold.woff2") format("woff2"), url("../fonts/Stem-Bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Stem-Regular";
  src: url("../fonts/Stem-Regular.woff2") format("woff2"), url("../fonts/Stem-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
:root {
  --bg-light-color: #fafafa;
  --bg-dark-color: #000000;
  --white-color: #ffffff;
  --accent-color: #d5001c;
  --dark-text-color: #151515;
  --padding-h-mob: 20px;
  --padding-v-mob: 40px;
  --padding-h-desktop: 84px;
  --padding-v-desktop: 60px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  position: relative;
  width: 100%;
  min-width: 320px;
  max-width: 1240px;
  margin: 0 auto;
  background-color: var(--bg-light-color);
  font-family: "Stem-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 20px;
  line-height: 1.1;
  overflow-x: hidden;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  opacity: 0.7;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

.visually-hidden:not(:focus):not(:active),
input[type=checkbox].visually-hidden,
input[type=radio].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.main__section {
  padding: var(--padding-v-mob) var(--padding-h-mob);
}
@media screen and (min-width: 1240px) {
  .main__section {
    padding: var(--padding-v-mob) var(--padding-h-desktop);
  }
}

.btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 274px;
  height: 60px;
  padding: 19px 24px;
  font-family: "Stem-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 17px;
  color: var(--white-color);
  background: transparent;
  border: 2px solid var(--accent-color);
  border-radius: 28px;
}
.btn:hover {
  background: linear-gradient(-45deg, var(--accent-color), #ff0022);
  color: var(--white-color);
  opacity: 1;
}
.btn--accent {
  background: linear-gradient(-45deg, var(--accent-color), #ff0022);
  border: none;
}
.btn--accent:hover {
  opacity: 0.7;
}

.swiper-button-prev {
  transform: rotate(180deg) !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: "" !important;
}

@media screen and (max-width: 479px) {
  body {
    font-size: 14px;
  }
  .main__section {
    padding: var(--padding-v-mob) 10px;
  }
  .btn {
    max-width: 246px;
  }
}
@media screen and (min-width: 1240px) {
  .main__section {
    padding: var(--padding-v-desktop) var(--padding-h-desktop);
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-h-mob);
  padding-bottom: 50px;
}
.header__logo {
  max-width: 214px;
}
.header__nav {
  display: none;
}
.header .btn {
  max-width: 188px;
  font-size: 15px;
}

@media screen and (max-width: 479px) {
  .header {
    padding: 10px;
    padding-bottom: 20px;
  }
  .header__logo {
    max-width: 130px;
  }
  .header .btn {
    max-width: 158px;
    font-size: 12px;
  }
}
@media screen and (min-width: 1100px) {
  .header__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  .header__nav-link {
    font-size: 17px;
  }
  .header__nav-link:hover, .header__nav-link:active {
    color: var(--accent-color);
  }
}
.advantages {
  position: relative;
  padding-bottom: 16px;
  background-color: var(--bg-dark-color);
  color: var(--white-color);
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38)), url("../images/ellips-bottom-big.png"), linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0.38)), url("../images/man-bg.webp");
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: cover, 800px 700px, 90px, 1020px;
  background-position: center, 50% 100%, top left, calc(100% + 390px) bottom;
  overflow-x: hidden;
}
.advantages__title {
  position: relative;
  font-size: 40px;
  max-width: 540px;
}
.advantages__logo {
  position: absolute;
  top: 40%;
  left: 0;
  max-width: 550px;
}
.advantages__list {
  max-width: 500px;
  margin-top: 120px;
}
.advantages__item {
  position: relative;
  margin-bottom: 28px;
  padding-left: 36px;
  font-family: "Stem-Regular", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
}
.advantages__item::before {
  content: "";
  display: block;
  width: 20px;
  height: 2px;
  position: absolute;
  top: 10px;
  left: 0;
  background-color: var(--accent-color);
}
.advantages__buttons {
  margin-top: 46px;
}
.advantages__buttons .btn {
  margin-bottom: 30px;
}
.advantages__notice {
  font-size: 20px;
}
.advantages__notice b {
  display: block;
  font-size: 55px;
}

@media screen and (max-width: 479px) {
  .advantages__title {
    font-size: 28px;
  }
  .advantages__logo {
    max-width: 350px;
  }
  .advantages__list {
    margin-top: 86px;
  }
  .advantages__item {
    font-size: 15px;
  }
  .advantages__notice b {
    display: block;
    font-size: 50px;
  }
}
@media screen and (min-width: 768px) {
  .advantages {
    border-radius: 30px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38)), url("../images/ellips.png"), url("../images/ellips-bottom-big.png"), linear-gradient(to right, rgb(0, 0, 0), rgba(0, 0, 0, 0.38)), url("../images/man-bg.webp");
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-size: cover, 480px 700px, 900px 400px, 189px, 1020px;
    background-position: top right, top right, 50% 100%, top left, calc(100% + 200px) bottom;
  }
}
@media screen and (min-width: 1240px) {
  .advantages {
    background-size: cover, 480px 700px, 900px 400px, 193px, 1048px;
    background-position: top right, top right, 50% 100%, 18%, right bottom;
  }
  .advantages__title {
    position: relative;
    font-size: 55px;
  }
  .advantages__title::after {
    content: "";
    display: block;
    width: 120px;
    height: 20px;
    position: absolute;
    bottom: -30px;
    right: -100px;
    background-image: url("../images/community.png");
  }
  .advantages__logo {
    width: 760px;
    max-width: unset;
    top: 22%;
  }
  .advantages__list {
    margin-top: 190px;
  }
  .advantages__item {
    font-size: 20px;
  }
  .advantages__buttons {
    display: flex;
    align-items: center;
    gap: 26px;
    margin-top: 76px;
    margin-bottom: 40px;
  }
  .advantages__buttons .btn {
    margin-bottom: 0;
  }
  .advantages__notice {
    position: absolute;
    bottom: 10%;
    right: 60px;
  }
}
.equipment {
  padding-bottom: 182px;
}
.equipment__title {
  margin-bottom: 56px;
  font-size: 36px;
}
.equipment__slider {
  position: relative;
  margin: 0 auto;
  margin-bottom: 40px;
  width: 100%;
  max-width: 430px;
  max-height: 287px;
  box-shadow: 12px -12px 0 var(--accent-color);
  border-radius: 30px;
  overflow: hidden;
}
.equipment__slider .swiper-button-prev,
.equipment__slider .swiper-button-next {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--accent-color);
}
.equipment__slider .swiper-button-prev svg,
.equipment__slider .swiper-button-next svg {
  width: 8px;
  fill: var(--white-color);
}
.equipment__slider .swiper-button-prev.swiper-button-disabled,
.equipment__slider .swiper-button-next.swiper-button-disabled {
  background-color: var(--white-color);
  opacity: 0.8;
}
.equipment__slider .swiper-button-prev.swiper-button-disabled svg,
.equipment__slider .swiper-button-next.swiper-button-disabled svg {
  fill: var(--bg-dark-color);
}
.equipment__sponsors-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 44px;
  margin-bottom: 30px;
}
.equipment__sponsors-item {
  max-width: 150px;
}
.equipment__sponsors-info span {
  display: block;
  font-size: 13px;
  margin-bottom: 2px;
  text-transform: uppercase;
  color: #b5b5b5;
}
.equipment__sponsors-info b {
  font-size: 20px;
}

@media screen and (max-width: 479px) {
  .equipment__title {
    font-size: 30px;
  }
  .equipment__slider {
    position: relative;
    margin-bottom: 40px;
    width: 100%;
    max-width: 280px;
    box-shadow: 12px -12px 0 var(--accent-color);
    border-radius: 30px;
    overflow: hidden;
  }
  .equipment__slider .swiper-button-prev,
  .equipment__slider .swiper-button-next {
    width: 44px;
    height: 44px;
  }
  .equipment__sponsors-item {
    max-width: 120px;
  }
}
@media screen and (min-width: 1240px) {
  .equipment__title {
    font-size: 55px;
  }
  .equipment__wrapper {
    display: flex;
    flex-direction: row-reverse;
    gap: 45px;
  }
  .equipment__sponsors {
    width: 51%;
    margin-top: 30px;
  }
  .equipment__sponsors-list {
    margin-bottom: 80px;
  }
  .equipment__slider {
    max-width: unset;
    max-height: 454px;
  }
}
.location {
  position: relative;
  background-color: var(--white-color);
  padding-bottom: 4px;
}
.location::before {
  content: "";
  display: block;
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 55px;
  background-image: url("../images/gde.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.location__title {
  max-width: 230px;
  margin-bottom: 24px;
  font-size: 30px;
}
.location__title::after {
  content: "";
  display: block;
  position: absolute;
  top: 20px;
  right: 10px;
  width: 40px;
  height: 50px;
  background-image: url("../images/map_point.svg");
  background-repeat: no-repeat;
}
.location .swiper-address {
  position: static;
  margin-bottom: 30px;
}
.location .swiper-address .swiper-slide {
  width: auto !important;
}
.location .swiper-address__btn {
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--bg-light-color);
  background: none;
  font-family: "Stem-Regular", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  color: #3a3a3a;
}
.location .swiper-address__btn.active {
  border: none;
  background-color: var(--accent-color);
  color: var(--white-color);
}
.location .swiper-address .swiper-button-prev.swiper-button-disabled,
.location .swiper-address .swiper-button-next.swiper-button-disabled {
  display: none;
}
.location .swiper-address .swiper-button-prev,
.location .swiper-address .swiper-button-next {
  top: 149px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: var(--white-color);
  box-shadow: 0 0 13px 8px rgba(78, 78, 77, 0.11);
}
.location .swiper-address .swiper-button-prev svg,
.location .swiper-address .swiper-button-next svg {
  width: 8px;
  fill: var(--bg-dark-color);
}
.location__wrapper {
  max-width: 720px;
  margin: 0 auto;
}
.location__map-list {
  height: 275px;
}
.location__map {
  display: none;
}
.location__map.active {
  display: block;
}
.location__map .ymap {
  width: 100%;
}
.location__slider {
  display: none;
  position: relative;
  margin-bottom: 40px;
  width: 100%;
  height: 500px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  overflow: hidden;
}
.location__slider.active {
  display: block;
}
.location__slider img {
  height: 100%;
}
.location__slider .swiper-button-prev,
.location__slider .swiper-button-next {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: var(--accent-color);
}
.location__slider .swiper-button-prev svg,
.location__slider .swiper-button-next svg {
  width: 8px;
  fill: var(--white-color);
}
.location__slider .swiper-button-prev.swiper-button-disabled,
.location__slider .swiper-button-next.swiper-button-disabled {
  background-color: var(--white-color);
  opacity: 0.8;
}
.location__slider .swiper-button-prev.swiper-button-disabled svg,
.location__slider .swiper-button-next.swiper-button-disabled svg {
  fill: var(--bg-dark-color);
}

@media screen and (min-width: 479px) {
  .location__title {
    max-width: 276px;
    font-size: 36px;
  }
  .location__title::after {
    top: 24px;
    right: 20px;
  }
  .location .swiper-address .swiper-button-prev,
  .location .swiper-address .swiper-button-next {
    top: 162px;
    right: 20px;
    width: 48px;
    height: 48px;
  }
  .location__slider .swiper-button-prev,
  .location__slider .swiper-button-next {
    width: 48px;
    height: 48px;
  }
}
@media screen and (min-width: 768px) {
  .location {
    border-radius: 30px;
  }
  .location::before {
    right: 20px;
    left: unset;
    height: 80px;
    background-position: right;
  }
}
@media screen and (min-width: 1240px) {
  .location {
    padding-bottom: var(--padding-v-desktop);
  }
  .location::before {
    top: -125px;
    right: 60px;
    height: 121px;
  }
  .location__title {
    max-width: unset;
  }
  .location__title::after {
    right: 26px;
  }
  .location .swiper-address {
    width: 1052px;
    margin-bottom: 45px;
    overflow: hidden;
  }
  .location .swiper-address .swiper-button-prev,
  .location .swiper-address .swiper-button-next {
    top: 140px;
  }
  .location .swiper-address .swiper-button-next {
    right: 80px;
  }
  .location .swiper-address .swiper-button-prev {
    right: unset;
    left: 80px;
  }
  .location__wrapper {
    display: flex;
    max-width: unset;
    border-radius: 30px;
    overflow: hidden;
  }
  .location__map-list {
    width: 326px;
    height: 500px;
  }
  .location .ymap {
    height: 500px;
  }
  .location__clubs-slider {
    width: 765px;
    height: 500px;
    overflow: hidden;
  }
  .location__slider {
    border-radius: 0;
  }
}
.prices {
  padding-bottom: 80px;
}
.prices__title {
  margin-bottom: 44px;
  max-width: 220px;
  font-size: 30px;
}
.prices__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}
.prices__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
  max-width: 336px;
}
.prices__image {
  border-radius: 30px;
}

@media screen and (min-width: 479px) {
  .prices__title {
    font-size: 36px;
  }
}
@media screen and (min-width: 1024px) {
  .prices__title {
    max-width: unset;
    font-size: 55px;
  }
  .prices__list {
    gap: 32px;
  }
}
.corporate {
  position: relative;
  padding-bottom: 16px;
  background-color: var(--bg-dark-color);
  color: var(--white-color);
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38)), url("../images/ellips.png"), url("../images/ellips-bottom.png"), url("../images/woman-bg.webp");
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: cover, 400px 400px, 380px 300px, 1090px;
  background-position: center, top right, -3px 100%, calc(100% + 263px) bottom;
  overflow-x: hidden;
}
.corporate__title {
  margin-bottom: 42px;
  font-size: 30px;
}
.corporate__wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.corporate__list {
  max-width: 406px;
}
.corporate__item {
  position: relative;
  margin-bottom: 28px;
  padding-left: 36px;
  font-family: "Stem-Regular", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 15px;
}
.corporate__item::before {
  content: "";
  display: block;
  width: 20px;
  height: 2px;
  position: absolute;
  top: 10px;
  left: 0;
  background-color: var(--accent-color);
}
.corporate__notice {
  margin-bottom: 72px;
  font-size: 45px;
}
.corporate__notice-old {
  position: relative;
  display: block;
  font-size: 34px;
  color: #d8d8d8;
}
.corporate__notice-old::after {
  content: "";
  display: block;
  width: 187px;
  height: 3px;
  position: absolute;
  bottom: 17px;
  left: 0;
  background-color: var(--accent-color);
  transform: rotate(-5deg);
}
.corporate__buttons .btn {
  margin-bottom: 30px;
}
.corporate__transparent-text {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 58px;
}

@media screen and (min-width: 479px) {
  .corporate {
    background-size: cover, 600px 600px, 380px 600px, 1172px;
    background-position: center, top right, -3px 100%, calc(100% + 220px) bottom;
  }
  .corporate__title {
    font-size: 36px;
  }
  .corporate__item {
    font-size: 20px;
  }
  .corporate__notice {
    font-size: 55px;
    line-height: 1;
  }
  .corporate__notice-old {
    font-size: 38px;
  }
  .corporate__notice-old::after {
    width: 210px;
  }
  .corporate__transparent-text {
    max-width: 76px;
  }
}
@media screen and (min-width: 1240px) {
  .corporate {
    padding-bottom: 40px;
    border-radius: 30px;
    overflow: hidden;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38)), url("../images/ellips.png"), url("../images/ellips-bottom.png"), linear-gradient(to right, rgb(0, 0, 0), rgba(0, 0, 0, 0.38)), url("../images/woman-bg.webp");
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-size: cover, 600px 600px, 380px 600px, 180px, 1076px;
    background-position: center, top right, -3px 100%, 23%, calc(100% + 89px) center;
  }
  .corporate__title {
    max-width: 768px;
    font-size: 55px;
  }
  .corporate__wrapper {
    flex-direction: row;
    align-items: center;
    gap: 240px;
  }
  .corporate__notice {
    font-size: 93px;
    line-height: 1;
    margin-bottom: auto;
  }
  .corporate__notice-old {
    display: flex;
    width: fit-content;
  }
  .corporate__notice-old::after {
    bottom: 16px;
  }
  .corporate__buttons {
    display: flex;
    align-items: center;
    gap: 26px;
    margin-top: 50px;
  }
  .corporate__transparent-text {
    bottom: 32px;
  }
}
.contacts {
  position: relative;
}
.contacts__title {
  max-width: 424px;
  margin-bottom: 20px;
  font-size: 36px;
  text-align: right;
}
.contacts__title--accent {
  display: block;
  text-align: start;
  color: var(--accent-color);
  font-size: 49px;
}
.contacts__text {
  max-width: 596px;
  margin-bottom: 42px;
  font-family: "Stem-Regular", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 20px;
}
.contacts__social {
  margin-bottom: 32px;
}
.contacts__social, .contacts__social-icons {
  display: flex;
  align-items: center;
}
.contacts__social-text {
  margin-right: 16px;
  font-size: 36px;
}
.contacts__social-icons {
  margin-left: auto;
}
.contacts__social-link {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--accent-color);
}
.contacts__social-link:first-child {
  margin-right: 26px;
}
.contacts__links {
  max-width: 725px;
  margin-bottom: 76px;
}
.contacts__link {
  display: block;
  font-size: 40px;
  color: var(--dark-text-color);
}
.contacts__link:hover, .contacts__link:active {
  color: var(--accent-color);
}
.contacts__buttons {
  margin-bottom: 20px;
}
.contacts__buttons .btn {
  margin-bottom: 30px;
  color: var(--dark-text-color);
}
.contacts__buttons .btn:hover {
  color: var(--white-color);
}
.contacts__buttons .btn--accent {
  color: var(--white-color);
}
.contacts__icon {
  width: 32px;
  height: 20px;
}

@media screen and (max-width: 479px) {
  .contacts__title {
    font-size: 30px;
  }
  .contacts__title--accent {
    font-size: 34px;
  }
  .contacts__text {
    font-size: 16px;
  }
  .contacts__social-text {
    font-size: 16px;
  }
  .contacts__social-link {
    width: 40px;
    height: 40px;
  }
  .contacts__social-link:first-child {
    margin-right: 10px;
  }
  .contacts__link {
    font-size: 28px;
  }
  .contacts__icon {
    width: 20px;
    height: 20px;
  }
}
@media screen and (min-width: 768px) {
  .contacts__title {
    max-width: 560px;
    margin: 0 auto;
    margin-bottom: 80px;
    font-size: 50px;
  }
  .contacts__title--accent {
    font-size: 64px;
  }
  .contacts__social-icons {
    margin-left: 0;
  }
}
@media screen and (min-width: 1240px) {
  .contacts__title {
    max-width: 860px;
    font-size: 66px;
    margin-bottom: 40px;
  }
  .contacts__title--accent {
    font-size: 100px;
  }
  .contacts__social-text, .contacts__link {
    font-size: 66px;
  }
  .contacts__icon {
    width: 36px;
    height: 25px;
  }
  .contacts__buttons {
    display: flex;
    align-items: center;
    gap: 26px;
  }
  .contacts__buttons .btn {
    margin-bottom: 0;
  }
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  padding: 18px 20px;
  background-color: var(--bg-dark-color);
  color: var(--white-color);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-family: "Stem-Regular", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 20px;
}
.footer__logo {
  max-width: 188px;
}

@media screen and (min-width: 768px) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
    padding: 13px 20px;
  }
  .footer__logo {
    max-width: 213px;
  }
}
.modal {
  display: none;
}
.modal.show {
  display: block;
}
.modal__wrapper {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}
.modal__body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--white-color);
}
.modal__title {
  text-align: center;
}
.modal__close {
  position: absolute;
  top: -20px;
  right: 10px;
  color: var(--white-color);
}
.modal__form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.modal__form label {
  margin-bottom: 8px;
}
.modal__form .errorMessage {
  display: block;
  max-width: 300px;
  width: 100%;
  background-color: rgb(231, 76, 60);
  border: 1px dashed black;
  border-radius: 20px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  margin: 0 auto 10px;
  padding: 6px;
  opacity: 0.9;
}
.modal__form .d-none {
  display: none;
}
.modal__form .btn-submit {
  height: 44px;
}
.modal__form .btn-submit:disabled {
  background-color: grey;
  opacity: 0.6;
}
.modal__input {
  margin-bottom: 10px;
  padding: 8px;
  font-family: "Stem-Bold", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.2;
  border-radius: 20px;
  border: 1px solid var(--dark-text-color);
  resize: none;
}
.modal__checkbox-text {
  font-size: 12px;
  font-weight: 300;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  display: flex;
  gap: 0.5rem;
  margin: 0.5rem 0;
  align-items: flex-start;
}